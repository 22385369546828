<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2 d-none">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="dark" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" md="4">
          <b-form-group label="Mobile" label-for="mobile">
            <b-form-input id="mobile" v-model="userData.mobile" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Current Plan -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="Current Plan"-->
        <!--            label-for="user-status"-->
        <!--          >-->
        <!--            <v-select-->
        <!--              v-model="userData.currentPlan"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="currentPlanOptions"-->
        <!--              :reduce="val => val.value"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-currentPlan"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Plan Validity -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="Plan Validity"-->
        <!--            label-for="planValidity"-->
        <!--          >-->
        <!--            <b-form-datepicker-->
        <!--              id="planValidity"-->
        <!--              v-model="userData.planValidity"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Role -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="User Role"-->
        <!--            label-for="user-role"-->
        <!--          >-->
        <!--            <v-select-->
        <!--              v-model="userData.role"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="roleOptions"-->
        <!--              :reduce="val => val.value"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-role"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Printable Address -->
        <b-col cols="12" md="4">
          <b-form-group label="Printable Address" label-for="address">
            <b-form-input id="address" v-model="userData.address.printable" />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 1 -->
        <b-col cols="12" md="4">
          <b-form-group label="Address Line 1" label-for="address-line1">
            <b-form-input id="address-line1" v-model="userData.address.address_line1" />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <b-col cols="22" md="4">
          <b-form-group label="Address Line 2" label-for="address-line2">
            <b-form-input id="address-line2" v-model="userData.address.address_line2" />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col cols="22" md="4">
          <b-form-group label="City" label-for="city">
            <b-form-input id="city" v-model="userData.address.city" />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col cols="22" md="4">
          <b-form-group label="State" label-for="state">
            <b-form-input id="state" v-model="userData.address.state" />
          </b-form-group>
        </b-col>

        <!-- Field: Zip -->
        <b-col cols="22" md="4">
          <b-form-group label="Zip" label-for="zip">
            <b-form-input id="zip" v-model="userData.address.zip" />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <b-col cols="12" md="4">
          <b-form-group label="Country" label-for="user-country">
            <v-select
              v-model="userData.address.country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countries"
              :clearable="false"
              input-id="user-country"
            />
          </b-form-group>
        </b-col>

        <!-- Field: TimeZone -->
        <b-col cols="12" md="4">
          <b-form-group label="TimeZone" label-for="user-timezone">
            <v-select
              v-model="userData.timezone"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tzInts"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-timezone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company -->
        <b-col cols="12" md="4">
          <b-form-group label="Company" label-for="company">
            <b-form-input id="company" v-model="userData.company" />
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col cols="12" md="4">
          <b-form-group label="Password" label-for="password">
            <b-form-input id="password" v-model="userData.password" type="password" />
          </b-form-group>
        </b-col>

        <!-- Kattooz ID -->
        <b-col cols="12" md="4">
          <b-form-group label="Kattooz ID" label-for="kattooz-id">
            <b-form-input id="kattooz-id" v-model="userData.kattooz_id" trim />
          </b-form-group>
        </b-col>

        <!-- API Key -->
        <b-col cols="12" md="4">
          <b-form-group label="API Key" label-for="api-key">
            <b-form-input id="api-key" v-model="userData.api_key" trim />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <b-card no-body class="border mt-1 d-none">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table borderless responsive class="mb-0" :items="permissionsData">
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="dark"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="editUser"
    >
      <b-spinner v-if="updating" style="margin:1px 3px" small />
      Save Changes
    </b-button>
    <b-button
      v-if="false"
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
/* eslint-disable no-console */
import {
  BButton,
  // BFormDatepicker,
  BMedia,
  BAvatar,
  BSpinner,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useUsersList from '../restaurants-list/useUsersList'

export default {
  components: {
    BButton,
    // BFormDatepicker,
    BMedia,
    BAvatar,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Client', value: 'client' },
    ]

    const currentPlanOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Advanced', value: 'advanced' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const tzInts = [
      {
        label: '(GMT-12:00) International Date Line West',
        value: 'Etc/GMT+12',
      },
      { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
      { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
      { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
      {
        label: '(GMT-08:00) Pacific Time (US & Canada)',
        value: 'America/Los_Angeles',
      },
      {
        label: '(GMT-08:00) Tijuana, Baja California',
        value: 'America/Tijuana',
      },
      { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
      {
        label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
        value: 'America/Chihuahua',
      },
      {
        label: '(GMT-07:00) Mountain Time (US & Canada)',
        value: 'US/Mountain',
      },
      { label: '(GMT-06:00) Central America', value: 'America/Managua' },
      { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
      {
        label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
        value: 'America/Mexico_City',
      },
      { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
      {
        label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
        value: 'America/Bogota',
      },
      { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
      { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
      { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
      { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
      { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
      { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
      { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
      { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
      {
        label: '(GMT-03:00) Buenos Aires, Georgetown',
        value: 'America/Argentina/Buenos_Aires',
      },
      { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
      { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
      { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
      { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
      { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
      {
        label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
        value: 'Africa/Casablanca',
      },
      {
        label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
        value: 'Etc/Greenwich',
      },
      {
        label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: 'Europe/Amsterdam',
      },
      {
        label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: 'Europe/Belgrade',
      },
      {
        label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
        value: 'Europe/Brussels',
      },
      {
        label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        value: 'Europe/Sarajevo',
      },
      { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
      { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
      {
        label: '(GMT+02:00) Athens, Bucharest, Istanbul',
        value: 'Europe/Athens',
      },
      { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
      { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
      { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
      {
        label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: 'Europe/Helsinki',
      },
      { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
      { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
      { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
      { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
      {
        label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
        value: 'Europe/Moscow',
      },
      { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
      { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
      { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
      { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
      { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
      { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
      { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
      { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
      {
        label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
        value: 'Asia/Karachi',
      },
      {
        label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        value: 'Asia/Calcutta',
      },
      { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
      { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
      { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
      { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
      { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
      { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
      { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
      {
        label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        value: 'Asia/Hong_Kong',
      },
      {
        label: '(GMT+08:00) Kuala Lumpur, Singapore',
        value: 'Asia/Kuala_Lumpur',
      },
      { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
      { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
      { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
      { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
      { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
      { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
      { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
      { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
      { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
      {
        label: '(GMT+10:00) Canberra, Melbourne, Sydney',
        value: 'Australia/Canberra',
      },
      { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
      { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
      { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
      {
        label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
        value: 'Asia/Magadan',
      },
      { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
      {
        label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
        value: 'Pacific/Fiji',
      },
      { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
    ]

    const countries = [
      'United Arab Emirates',
      'United Kingdom',
      'USA',
      'India',
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      "Côte d'Ivoire",
      'Cabo Verde',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo',
      'Costa Rica',
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czechia',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Eswatini',
      'Ethiopia',
      'Fiji',
      'Finland',
      'France',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Holy See',
      'Honduras',
      'Hungary',
      'Iceland',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'North Korea',
      'North Macedonia',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestine State',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent & the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Korea',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Sweden',
      'Switzerland',
      'Syria',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Vietnam',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const updating = ref(false)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const editUser = () => {
      updating.value = true
      const { userData } = props
      delete userData.menus
      store
        .dispatch('app-user/editUser', {
          id: router.currentRoute.query.id,
          userData,
        })
        .then(() => {
          updating.value = false
          // if (response.data.type === 'success') {
          //   this.$toast({
          //     component: ToastificationContent,
          //     position: 'top-right',
          //     props: {
          //       title: 'saved successfully!',
          //       icon: 'CoffeeIcon',
          //       variant: 'success',
          //     },
          //   })
          // } else {
          //   this.$toast({
          //     component: ToastificationContent,
          //     position: 'top-right',
          //     props: {
          //       title: 'Failed to save',
          //       icon: 'CoffeeIcon',
          //       variant: 'danger',
          //       text: response.data.display,
          //     },
          //   })
          // }
        })
        .catch(() => {
          updating.value = false
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: 'Failed to save',
          //     icon: 'CoffeeIcon',
          //     variant: 'danger',
          //     text: error.message,
          //   },
          // })
        })
    }

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      currentPlanOptions,
      statusOptions,
      tzInts,
      countries,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      editUser,
      updating,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
